import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChatList from './ChatList';
import { useParams } from 'react-router-dom';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { expertid, userid } = useParams();
  const userType = localStorage.getItem("userType")
  const [chatWith,setChatWith] =useState("") 

  useEffect(() => {

    fetchMessages();
    fetchUsersname()
  }, [expertid,userid]);

  const fetchUsersname = async () => {
    try {
      const url=process.env.REACT_APP_API_URL +`chat/getusername/${expertid}/${userid}`;
      const response = await axios.get(url);
      setChatWith(response.data.expertname);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchMessages = async () => {
    try {
      const url=process.env.REACT_APP_API_URL+ `chat/chatbetween/${expertid}/${userid}`
      const response = await axios.get(url);
      setMessages(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  // const interval = setInterval(fetchMessages, 5000);

  const handleSendMessageByStudent = async () => {
    if (newMessage.trim() === '') {
      return;
    }
    try {
      setIsLoading(true)
      const url = process.env.REACT_APP_API_URL +"chat/createchatbyuser";
      await axios.post(url, {
        expertid,
        userid,
        message: newMessage
      });
      // console.log("message sent")
      setIsLoading(false)
      setNewMessage('');
      fetchMessages();


    } catch (err) {
      console.error(err);
    }
  };
  const handleSendMessageByExpert = async () => {
    if (newMessage.trim() === '') {
      return;
    }
    try {
      setIsLoading(true);
      const url = process.env.REACT_APP_API_URL + "chat/createchatbyexpert";
      await axios.post(url, {
        expertid,
        userid,
        message: newMessage
      });
      // console.log("message sent")
      setIsLoading(false)
      setNewMessage('');
      fetchMessages();


    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="container">
      <div className="row">
        <ChatList />
        <div className="col-xl-8 col-lg-8 chat-container">
          <div className="chat-header">
            <span className="">
              <i className="fa-solid fa-user"></i> {chatWith}
            </span>
          </div>
          <div className="chat-messages">
            {messages.map((msg) => (
              <div key={msg.srno} className={`chat-message ${msg.chatby}`}>
                <div className="message-info">
                  <span className="message-author">{msg.chatby}</span>
                  <span className="message-time">{msg.time}</span>
                </div>
                <div className="message-text">{msg.message}</div>
              </div>
            ))}
          </div>
          <div className="chat-input-container">
            <input className="chatinput form-control" type="text" value={newMessage} required placeholder="Enter Message....." onChange={(e) => (setNewMessage(e.target.value))} />
            {
              userType === 'expert' ? <button className="send-button btn btn-primary" disabled={isLoading} onClick={handleSendMessageByExpert}>
                <i className="fa-solid fa-circle-arrow-up"></i>
              </button> : <button className="send-button btn btn-primary" disabled={isLoading} onClick={handleSendMessageByStudent}>
                <i className="fa-solid fa-circle-arrow-up"></i>
              </button>
            }
          </div>
        </div>
      </div>
    </div>

  );
};

export default Chat;