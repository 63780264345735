import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login.css';
import { DataAppContext } from '../DataContext';

const Login = () => {
    const [credentials, setCredentials] = useState({ usernameOrEmail: '', password: '' });
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const log = useContext(DataAppContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);  // Start loader

        // Ensure trimming of inputs
        const trimmedCredentials = {
            usernameOrEmail: credentials.usernameOrEmail.trim(),
            password: credentials.password.trim()
        };

        // console.log('Submitting credentials:', trimmedCredentials); // Debugging log

        try {
            const headers = {
                'x-access-token': localStorage.getItem('jwttoken')
            };

            const response = await axios.post(`${apiUrl}users/login`, trimmedCredentials, { headers });
            setLoader(false);

            const { token, user, userType} = response.data;
            if (response.status === 202) {
                localStorage.setItem('jwttoken', token);
                localStorage.setItem('userType', user.usertype);
                localStorage.setItem('userid', user.userId);
                localStorage.setItem('username', user.username);
                log.login_user();
                toast.success('Login successful!');
                
                if (user.usertype === 'admin') {
                    navigate('/dashboard');
                } else {
                    navigate('/home');
                }
            } else {
                setError('Unexpected response from server.');
            }
        } catch (err) {
            setLoader(false);
            console.error('Error during login:', err); 

            if (err.response) {
                setError(err.response.data.message || 'Login failed.');
                toast.error(err.response.data.message || 'Login failed.');
            } else {
                setError('Login failed. Please try again later.');
                toast.error('Login failed. Please try again later.');
            }
        }
    };

    return (
        <div className='login'>
            <ToastContainer />
            <form className='login-container' onSubmit={handleSubmit}>
                <div className='login-title'>
                    <h2>Login</h2>
                </div>
                <div className='login-input'>
                    <input
                        name='usernameOrEmail'
                        type='text'
                        placeholder='Username, Email, or Mobile'
                        required
                        value={credentials.usernameOrEmail}
                        onChange={handleInputChange}
                    />
                    <input
                        name='password'
                        type='password'
                        placeholder='Password'
                        required
                        value={credentials.password}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='login-condition'>
                    <input type='checkbox' required />
                    <p>By continuing, I accept the terms and conditions</p>
                </div>
                {error && <p className="error">{error}</p>}
                <button type='submit' disabled={loader}>
                    {loader ? 'Loading...' : 'Log in'}
                </button>
            </form>
        </div>
    );
};

export default Login;
