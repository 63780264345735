import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
const ExpertOnHome = () => {

  const navigate = useNavigate();
  const userid = localStorage.getItem("userid")
  const [experts, setExperts] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  const headers = {
    'x-access-token': localStorage.getItem('jwttoken') || "",
  };

  const fetchExperts = async () => {

    try {
      const url = process.env.REACT_APP_API_URL + `experts/distinctexperts`;
      const response = await axios.get(url, { headers });
      setExperts(response.data[0]);
    } catch (error) {
      console.log("Error: " ,error);
    }
  };

  useEffect(() => {
    fetchExperts();
  }, []);
  return (
    <>
      <div className="tp-team-4__area">
        <div className="container">
          <div className="expert-intro text-center mb-4">
            <h2 className="expert-intro__title">Meet Our Experts</h2>
            <p className="expert-intro__subtitle">
              Connecting you with experienced professionals dedicated to guiding your journey forward.
            </p>
          </div>

          <div className="row">
            {experts &&
              experts.map((expert) => (
                <div
                  key={expert.expertid}
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4 wow tpfadeUp"
                  data-wow-duration=".9s"
                  data-wow-delay=".3s"
                >
                  <Link to={`/expertdetail/${expert.expertid}`} className="text-decoration-none">
                    <div className="card expert-card shadow-sm border-0">
                      <div className="card-img-top p-relative">
                        <img
                          className="w-100 expert-image"
                          src={apiUrl + expert.profile_image}
                          alt={expert.name}
                          loading="lazy"
                        />
                        <div className="expert-social-wrapper position-absolute top-0 end-0 p-2">
                          {/* <a href="#" className="me-2"><i className="fa-brands fa-facebook-f"></i></a>
                    <a href="#" className="me-2"><i className="fa-brands fa-twitter"></i></a>
                    <a href="#"><i className="fa-brands fa-linkedin-in"></i></a> */}
                        </div>
                      </div>
                      <div className="card-body text-center">
                        <h6 className="card-title mb-1">{expert.name}</h6>
                        <p className="card-subtitle text-muted mb-1">{expert.designation}</p>
                        <p className="card-text text-muted small">{expert.collegename}</p>
                        {userid && (
                          <Link to={`/chat/${expert.expertid}/${userid}`} className="btn btn-primary mt-3">
                            Chat with Expert
                          </Link>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>

    </>
  );
};

export default ExpertOnHome;
